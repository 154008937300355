import React, { useState, useEffect, useRef } from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import LoadingIcon from "../custom/inputs/ERPSLoadingIcon.js";

import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";

import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";

import parse from "html-react-parser";
import { loginMessageManagementAction } from "../../actions/loginMessage.action";
import { validateURL } from "../../utils/helper";
import Footer from "../Footer/Footer";
import ErrorMessage from "../custom/containers/ErrorMessage";

export default function WebportalLandingPage(props) {
  let currentUrl = window.location.href;
  const dispatch = useDispatch();
  const errorRef = useRef();

  const currentFI = currentUrl.split("/")[2].split(".")[0];
  const fiName = localStorage.getItem("fiName");

  const hasAdminSiteUrl = /admin/.test(currentFI);
  const getDashboardMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardMessage
  );
  const appConfig = useSelector(
    (state) => state.appConfigReducer?.data?.API_BASE_URL
  );
  const getDashboardErrorMessage = useSelector(
    (state) => state.loginMessageManagementReducer?.getDashboardError
  );
  const { t, i18n } = useTranslation();
  const [languageCode, setLanguageCode] = React.useState(i18n.language);
  const [showExceptionMessage, setShowExceptionMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [messagesList, setMessagesList] = useState([
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.french_label"),
      },
      messageId: 1,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.french_label"),
      },
      messageId: 2,
    },
    {
      en: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.english_label"),
      },
      fr: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.french_label"),
      },
      messageId: 3,
    },
  ]);
  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    if (!hasAdminSiteUrl && appConfig) {
      // window.location.reload();
      dispatch(
        loginMessageManagementAction.getLoginMessage({
          t,
          setShowExceptionMessage,
          setLoading,
        })
      );
    }
  }, [appConfig]);
  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    if (
      getDashboardMessage &&
      Array.isArray(getDashboardMessage) &&
      getDashboardMessage.length
    ) {
      let dataV1 = [];
      getDashboardMessage?.forEach((data) => {
        dataV1.push({
          en: {
            header: data?.messageHeaderEnglish,
            message: data?.messageTextEnglish,
            urlText: data?.messageLinkTextEnglish,
            urlLink: data?.messageUrlEnglish,
            type: t("erps.header.language.english_label"),
          },
          fr: {
            header: data?.messageHeaderFrench,
            message: data?.messageTextFrench,
            urlText: data?.messageLinkTextFrench,
            urlLink: data?.messageUrlFrench,
            type: t("erps.header.language.french_label"),
          },
          messageId: data?.id,
        });
      });
      setMessagesList(dataV1);
    } else if (
      getDashboardMessage &&
      Array.isArray(getDashboardMessage) &&
      getDashboardMessage.length == 0
    ) {
      props.history.push("/webportal-redirect");
    }
  }, [getDashboardMessage]);
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [getDashboardErrorMessage]);
  const getCardComponent = (language) => {
    const messagesContent = messagesList.map((data) => data[language]);
    const hasMessage =
      getDashboardMessage && getDashboardMessage.length ? true : false;
      // If there are messages, return to welcome page
    //BugNo:3430 | Modified By:Mohana lakshmi Moka | Modified Date:16/08/2024
    if (hasMessage) {
    return (
      <>
        <Card className="messageCenter__card__container">
          {hasMessage && (
            <CardContent>
              <Typography
                variant="h2"
                className="messageCenter__card__content__header"
              >
                {t("erps.login.message.card.title", "Messages")}
              </Typography>
              {messagesContent.map(
                ({ header, message, urlText, urlLink }, idx) => (
                  <Box mt={2}>
                    {header && (
                      <Typography
                        variant="h3"
                        className="messageCenter__card__content__heading"
                      >
                        {header}
                      </Typography>
                    )}
                    <Typography
                      variant="body1"
                      className="signIn__card__content__header"
                    >
                      {message ? parse(message) : ""}
                      {message && <br />}
                      <ERPSBaseLink
                        label={urlText}
                        to={{
                          pathname: !validateURL(urlLink)
                            ? `https://${urlLink}`
                            : urlLink,
                        }}
                        target={urlLink ? "_blank" : ""}
                        variant="link"
                        className="signIn__card__content__link"
                      />
                    </Typography>
                  </Box>
                )
              )}
            </CardContent>
          )}
        </Card>
      </>
    );
  }
  //If there are no messages, return to CIBC Login page
  else {
    return (
      props.history.push("/webportal-redirect"))
  }
  //End
  };

  const getTitle = () => (
    <title>
      {fiName === "bmo"
        ? `${t("erps.app.title.message.bmo", "Merchant Bill Pay Service")}-${t(
            "erps.welcome.page.title",
            "Welcome"
          )}`
        : `${t(
            "erps.app.title.message",
            "Bill payment remittance reporting"
          )}-${t("erps.welcome.page.title", "Welcome")}`}
    </title>
  );

  const hasError = showExceptionMessage && getDashboardErrorMessage;
  if (
    (!appConfig && !(getDashboardMessage && getDashboardMessage.length)) ||
    isLoading
  ) {
    return (
      <Box textAlign={"center"} mt={30}>
        <LoadingIcon />
      </Box>
    );
  } else {
    return (
      <div>
        <Header {...props} />

        <Helmet>{getTitle()}</Helmet>

        <Box mt={12}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={3}
          >
            <Typography variant="h1" className="text__color__heading">
              {t("erps.welcome.page.title", "Welcome")}
            </Typography>
          </Box>

          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {getCardComponent(languageCode)}
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              onClick={() => props.history.push("/webportal-redirect")}
              label={t("erps.login.pagetitle.message")}
            />
          </Box>
        </Box>

        <Footer />
      </div>
    );
  }
}