import axios from "axios";
import loginActionTypes from "../actions/types/login.action.type";
import store from "../store/index";
import i18next from "i18next";
import {
  API_BASE_URL,
  getErrorMessageFromException,
  getExceptionMessage,
  roleConfigUrls,
} from "../utils/helper";

export const loginService = {
  login,
  logout,
  updateServiceAggrement,
  updateUserRolesPermission,
};

function login(username, password, props) {
  let fiNum = null,
    type = null;
  if (localStorage.getItem("fiNumber")) {
    fiNum = localStorage.getItem("fiNumber");
  }

  if (localStorage.getItem("type")) {
    type = localStorage.getItem("type");
  }

  return (dispatch) => {
    axios
      .post(
        API_BASE_URL() + "/users/authenticate",
        { fiNumber: fiNum, userType: type, userId: username },
        {
          headers: {
            "Content-Type": "application/json",
            "Login-UserId": username,
          },
        }
      )
      .then((res) => {
        props.setLoginPending(false);
        dispatch(success(res));

        const data = JSON.parse(JSON.stringify(res.data));
        if (data) {
          const {
            role: { role_type },
          } = data;

          if (
            role_type === "MA" &&
            data.billerNonVolatileDto &&
            data.billerNonVolatileDto.agreement_accepted != "A"
          ) {
            props.history.push("/welcome-page");
          } else {
            if (data.statusCode !== "I") {
              if (data.changePasswordIndex == "N") {
                if (data.emailAddress || data.emailAddress != "") {
                  const roleId = data?.role?.role_id;
                  let path;
                  if (roleId == 7) {
                    path =
                      data?.fiNumber == 10 || data?.fiNumber == 16
                        ? "/manage-billers"
                        : "/review-transactions";
                  } else {
                    path = roleConfigUrls[roleId] || "/dashboard";
                  }

                  props.history.push(path);
                } else {
                  props.history.push("/account-settings");
                }
              } else {
                if (data.emailAddress || data.emailAddress != "") {
                  props.history.push("/update-password");
                } else {
                  props.history.push("/account-settings");
                }
              }
            }
          }
        }

        if (data.languageCode == "E") {
          i18next.changeLanguage("en");
        } else if (data.languageCode === "F") {
          i18next.changeLanguage("fr");
        }
      })
      .catch((error) => {
        const errMessage = getExceptionMessage(error, props.t);
        props.setLoginPending(false);
        dispatch(failure(errMessage));
      });
  };

  function request(data) {
    return { type: loginActionTypes.AUTHENTICATION_REQUEST, data };
  }

  function success(data) {
    return { type: loginActionTypes.AUTHENTICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: loginActionTypes.AUTHENTICATION_FAIL, error };
  }
}

function logout(props) {
  return (dispatch) => {
    dispatch(request());
    i18next.changeLanguage("en");
    dispatch(success(null, props));
    if (props.setOpen) {
      props.setOpen(false);
    }
    //  window.location.href = "/";
    localStorage.removeItem("persist:erps");
    if (props?.history) {
      props.history.push("/");
    }
    //authAxios
    // axios
    //   .get(API_BASE_URL() + "/logoutUser", {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(
    //     async (res) => {
    //       i18next.changeLanguage("en");
    //       dispatch(success(res, props));
    //       if (props.setOpen) {
    //         props.setOpen(false);
    //       }
    //       //  window.location.href = "/";
    //       localStorage.removeItem("persist:erps");
    //       if (props?.history) {
    //         props.history.push("/");
    //       }
    //     },
    //     (error) => {
    //       if (props.setLoading) {
    //         props.setLoading(false);
    //       }
    //       const errorResponse = getErrorMessageFromException(error, props.t);
    //       if (!errorResponse) {
    //         const parsedError = JSON.parse(JSON.stringify(error));
    //         if (
    //           parsedError &&
    //           parsedError.message &&
    //           parsedError.status !== 401
    //         ) {
    //           dispatch(failure(parsedError.message));
    //         }
    //       } else {
    //         dispatch(failure(errorResponse));
    //       }

    //       if (props.setOpen) {
    //         props.setOpen(false);
    //       }
    //       props.history.push("/");
    //     }
    //   );
  };

  function request() {
    return { type: loginActionTypes.LOGOUT_REQUEST };
  }

  function success(res, props) {
    return {
      type: loginActionTypes.LOGOUT_SUCCESS,
      res,
      ...(props?.idleLogoutMsg && { message: props.idleLogoutMsg }),
    };
  }

  function failure(error) {
    return { type: loginActionTypes.LOGOUT_FAIL, error };
  }
}
function updateServiceAggrement(data, props) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .put(API_BASE_URL() + "/service-agreement/update", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        props.setLoadingOnSubmit(false);
        dispatch(success(res.data));
        props.history.push("/account-registration");
      })
      .catch((error) => {
        // const errMessage = JSON.parse(JSON.stringify(error)).message;
        props.setLoadingOnSubmit(false);
        const errorResponse = getErrorMessageFromException(error) || error;

        //   dispatch(showSnackbar(errMessage));
        dispatch(failure(errorResponse));
      });
  };
  function success(res) {
    return {
      type: loginActionTypes.UPDATE_SERVICE_AGREEMENT_SUCCESS,
      data: res,
    };
  }

  function failure(error) {
    return { type: loginActionTypes.UPDATE_SERVICE_AGREEMENT_ERROR, error };
  }
}

function updateUserRolesPermission(fiNumber, roleId, props) {
  return (dispatch) => {
    const fiToRedirectDashborad = ["2", "10", "99"];
    const fiToRedirectFIManagment=["60","70","97"];
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        `${API_BASE_URL()}/load-roles-permissions?roleId=${roleId}&&fiNumber=${fiNumber}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch(success(res.data));
        if (roleId == 11) {
          props?.history.push("/manage-users", { dropDownChange: true });
        } else if (fiToRedirectDashborad.includes(fiNumber)) {
          props?.history.push("/dashboard");
        }else if (fiToRedirectFIManagment.includes(fiNumber)) {
          props?.history.push("/view-financial-institution");
        }
        else if (fiNumber == 50) {
          props?.history.push("/manage-billers");
        } else {
          props?.history.push("/manage-users", { dropDownChange: true });
        }
      })
      .catch((error) => {
        const errorResponse = getErrorMessageFromException(error) || error;
        dispatch(failure(errorResponse));
      });
  };
  function success(res) {
    return {
      type: loginActionTypes.UPDATE_USER_ROLE_SUCCESS,
      data: res,
    };
  }
  function failure(error) {
    return { type: loginActionTypes.UPDATE_USER_ROLE_ERROR, error };
  }
}

