import React, { useState, useEffect, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { marketManagementAction } from "../../actions/marketingManagement.action";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../custom/containers/ErrorMessage";
import parse from "html-react-parser";
import { validateURL } from "../../utils/helper";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

function Dashboard(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getDashboardErrorMessage = useSelector(
    (state) => state.marketingManagementReducer?.getDashboardError
  );
  const getDashboardMessage = useSelector(
    (state) => state.marketingManagementReducer?.getDashboardMessage
  );
  const language = useSelector(
    (state) => state.loginUserReducer?.user?.languageCode
  );
  const [showExceptionMessage, setShowExceptionMessage] = useState(false);

  const uniqueEmailAddress = useSelector(
      (state) => state.loginUserReducer?.user?.uniqueEmailAddress
  );

  const [billerId] = useSelector(
      (state) => state.loginUserReducer?.user?.searchBillerFilesDto?.fi_biller_with_biller_id.split('-')
  );


  const [openCancelDialog, setOpenCancelDialog] = useState(uniqueEmailAddress === "N");

  const popUpLoadedOnce = localStorage.getItem("popUpLoadedOnce");

  const handleRedirect = () => {
    localStorage.setItem("popUpLoadedOnce", true);
    props.history.push("/account-settings");
  };


  const [messagesList, setMessagesList] = useState([
    {
      E: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.english_label"),
      },
      F: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 1,
        type: t("erps.header.language.french_label"),
      },
      messageId: 1,
    },
    {
      E: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.english_label"),
      },
      F: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 2,
        type: t("erps.header.language.french_label"),
      },
      messageId: 2,
    },
    {
      E: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.english_label"),
      },
      F: {
        header: "",
        message: "",
        urlText: "",
        urlLink: "",
        id: 3,
        type: t("erps.header.language.french_label"),
      },
      messageId: 3,
    },
  ]);
  useEffect(() => {
    dispatch(
      marketManagementAction.getDashboardMessage({
        setShowExceptionMessage,
        t,
      })
    );
  }, []);
  useEffect(() => {
    if (getDashboardMessage && getDashboardMessage.length) {
      let dataV1 = [];
      getDashboardMessage.forEach((data) => {
        dataV1.push({
          E: {
            header: data?.messageHeaderEnglish,
            message: data?.messageTextEnglish,
            urlText: data?.messageLinkTextEnglish,
            urlLink: data?.messageUrlEnglish,
            type: t("erps.header.language.english_label"),
          },
          F: {
            header: data?.messageHeaderFrench,
            message: data?.messageTextFrench,
            urlText: data?.messageLinkTextFrench,
            urlLink: data?.messageUrlFrench,
            type: t("erps.header.language.french_label"),
          },
          messageId: data?.id,
        });
      });
      setMessagesList(dataV1);
    }
  }, [getDashboardMessage]);

  const handleCloseCancelDialog = () => {
    localStorage.setItem("popUpLoadedOnce", true);
    setOpenCancelDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const getDialogContent = () => {
    return (
          <Box>
            <Typography gutterBottom aria-describedby="dialog-message">
              {t("erps.email.change.popup.body.contentV1")}
              {`${billerId}`}
              {t("erps.email.change.popup.body.contentV2")}
            </Typography>
            <Typography gutterBottom aria-describedby="dialog-message">
              {t("erps.email.change.popup.body.contentV3")}
            </Typography>
          </Box>
    );
  };

  const getCardComponent = (lang) => {
    const messagesContent = messagesList.map((data) => data[lang]);

    return (
      <>
        <Card className="messageCenter__card__container">
          <CardContent>
            <Typography
              variant="h2"
              className="messageCenter__card__content__header"
            >
              {t("erps.login.message.card.title", "Messages")}
            </Typography>
            {messagesContent.map(
              ({ header, message, urlText, urlLink }, idx) => (
                <Box mt={2}>
                  {header && (
                    <Typography
                      variant="h3"
                      className="messageCenter__card__content__heading"
                    >
                      {header}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    className="signIn__card__content__header"
                  >
                    {message ? parse(message) : ""}
                    {message && <br />}
                    <ERPSBaseLink
                      label={urlText || urlLink}
                      to={{
                        pathname: !validateURL(urlLink)
                          ? `https://${urlLink}`
                          : urlLink,
                      }}
                      target={"_blank"}
                      className="signIn__card__content__link"
                    />
                  </Typography>
                </Box>
              )
            )}
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <main>
      <Box ml={3} mt={2}>
        {showExceptionMessage && getDashboardErrorMessage && (
          <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
            <ErrorMessage error={getDashboardErrorMessage} />
          </Box>
        )}
        <Box mt={4}>
          <Typography variant="h1">
            {t("erps.home.dashboard.heading.dashboard")}
          </Typography>
        </Box>
        <Box>
          {getDashboardMessage && getDashboardMessage.length
            ? getCardComponent(language)
            : ""}
        </Box>
        {!popUpLoadedOnce && (
        <Dialog
            open={openCancelDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
          <DialogHeader
              id="alert-dialog-title"
              onClose={handleCloseCancelDialog}
          >
            {t("erps.email.change.popup.header.title")}
          </DialogHeader>
          <DialogContent
              id="alert-dialog-description"
          >
            {getDialogContent()}
          </DialogContent>
          <DialogActions >
            <ERPSBaseButton
                variant="secondary"
                label={t("erps.email.change.popup.yes")}
                onClick={handleRedirect}
            />
            <ERPSBaseButton
                variant="primary"
                label={t("erps.email.change.popup.no")}
                onClick={handleCloseCancelDialog}
            />
          </DialogActions>
        </Dialog> )}
      </Box>
    </main>
  );
}

export default withSidebar(Dashboard);
